<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<number-bars class="number-bars--on-orange number-bars--align-left" :title="`Settlement \nVolume for \nInsurance (STL)`" unit="( in thousands )" :numbers="settlementVolumes" />

			<number-bars class="number-bars--on-orange number-bars--align-left" :title="`Total \nTransaction \nVolume`" unit="( in billions )" :numbers="insuranceVolume" />
		</div>
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'InsuranceRetirement2',
	components: {
		NumberBars,
	},
	data: () => ({
		settlementVolumes: [
			{
				value: 5625,
				label: '2020',
			},
			{
				value: 5084,
				label: '2019',
			},
			{
				value: 4253,
				label: '2018',
			},
		],
		insuranceVolume: [
			{
				value: 14.1,
				label: '2020',
				options: {
					decimalPlaces: 1,
				},
			},
			{
				value: 14.5,
				label: '2019',
				options: {
					decimalPlaces: 1,
				},
			},
			{
				value: 13.7,
				label: '2018',
				options: {
					decimalPlaces: 1,
				},
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
